<template>
    <div>
        <div class="form-row">
            <div class="form-col">
                <p>add iframe code to embed customized component into site.</p>
                <textarea v-model="content" class="border w-1/2 h-full mt-8 p-4 focus:outline-none"></textarea>
                <span v-if="hasError('content')" class="form-error">{{ showError('content') }}</span>
            </div>
        </div>

        <modal-footer :primary="save"></modal-footer>
    </div>
</template>

<script>
import Widget from "@/mixins/Widget";
import ModalNavigation from "@/mixins/ModalNavigation";
import ModalFooter from "@/components/ui/modals/ModalFooter";

export default {
    mixins: [ ModalNavigation, Widget ],
    components: { ModalFooter },
    emits: ['save-page-widget'],
    data: () => {
        return {
            content: null,
        }
    },
    methods: {
        save() {
            if(this.validator.passes()) {
                this.$emit('save-page-widget', { content: this.content });
            }
        },
    },
    validations: {
        'content' : 'required',
    },
    created() {
        this.initValidator();
        if(this.values.length)
            this.content = this.getValue('content');
    }
}
</script>
